// extracted by mini-css-extract-plugin
export var actions = "br_bP";
export var articleMultipleChoiceCTA = "br_dz";
export var body = "br_dC";
export var bodyContainer = "br_dB";
export var illustration = "br_dF";
export var selected = "br_dJ";
export var subtitle = "br_dH";
export var textContent = "br_dG";
export var tile = "br_dD";
export var wrapper = "br_bp";